const currencyFormat = (num) => {
  const value = new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "RUB",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(num);
  return value;
};

let totalCost = 0;
let totalDays = 0;
const components = document.querySelectorAll(".calculator .list li");
const descriptionContainer = document.getElementById("element_container");

components.forEach((component) => {
  component.addEventListener("click", function () {
    // Если текущий компонент уже активен, просто его деактивируем и очищаем описание
    if (this.classList.contains("active")) {
      this.classList.remove("active");
      descriptionContainer.innerHTML = "";
      updateTotals();
      return;
    }

    // Деактивируем все компоненты
    components.forEach((comp) => comp.classList.remove("active"));

    // Активируем текущий компонент
    this.classList.add("active");

    // Обновляем описание для активного компонента
    const descriptionText = this.getAttribute("data-description");
    descriptionContainer.innerHTML = `<span class="description_element">${descriptionText}</span>`;

    // Обновляем общую стоимость и время
    updateTotals();
  });
});

function updateTotals() {
  totalCost = 0;
  totalDays = 0;
  descriptionContainer.innerHTML = ""; // Очищаем описание

  // Находим активный компонент
  const activeComponent = document.querySelector(".calculator .list li.active");

  // Если есть активный компонент, обновляем стоимость, время и описание
  if (activeComponent) {
    totalCost = parseInt(activeComponent.getAttribute("data-cost"), 10);
    totalDays = parseInt(activeComponent.getAttribute("data-days"), 10);

    // Обновляем описание для активного компонента
    const descriptionText = activeComponent.getAttribute("data-description");
    descriptionContainer.innerHTML = `<span class="description_element">${descriptionText}</span>`;
  }

  // Обновляем общую стоимость и время на странице
  document.getElementById("total_site").textContent = currencyFormat(totalCost);
  document.getElementById("total_days").textContent =
    totalDays + (totalDays === 1 ? " день" : " дн.");
}
document.addEventListener("DOMContentLoaded", (event) => {
  updateTotals();
});
